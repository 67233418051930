<template>
    <v-app>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-12">
                              <h3>
                                Security settings
                              </h3>
                              <router-link :to="{name:'dashboard'}">
                                Dashboard
                              </router-link>
                              \
                              Security settings
                            </div>
                            <div class="col-6 text-right">

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <v-col cols="12" md="6" class="card p-10">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h3>Setup MFA</h3>
                                        <p>Use multi-factor authentication (MFA) to add an extra layer of security to your account.</p>
                                    </div>
                                    <div class="col-md-4 text-right">
                                        <p>
                                            <img
                                                    src="https://cdn2.iconfinder.com/data/icons/plex-for-android/96/authenticator.png"
                                                    style="max-width: 64px"
                                            />
                                        </p>
                                    </div>
                                    <v-btn
                                            v-if="!this.user.is_google_two_factor_authentication_enabled"
                                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                            class="btn btn-primary text-white"
                                            text
                                            :loading="isGoogleAuthLoading"
                                            @click="getGoogleAuthenticatorCode"
                                    >Setup authenticator</v-btn>
                                    <v-btn
                                            v-else
                                            color="white"
                                            class="btn btn-danger text-white"
                                            text
                                            :loading="isGoogleAuthLoading"
                                            @click="deActivateGoogleAuthenticator"
                                    >Deactivate authenticator</v-btn>
                                </div>
                            </div>
                        </v-col>
<!--                        <v-col cols="12" md="6" class="card p-10 mt-10">-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-md-8">-->
<!--                                        <h3>Setup MFA - Email Verification</h3>-->
<!--                                        <p>Use multi-factor authentication (MFA) to add an extra layer of security to your account.</p>-->
<!--                                    </div>-->
<!--                                    <div class="col-md-4 text-right">-->
<!--                                        <p>-->
<!--                                            <img-->
<!--                                                    src="https://img.icons8.com/plasticine/100/000000/important-mail.png"-->
<!--                                                    style="max-width: 64px"-->
<!--                                            />-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                    <v-btn-->
<!--                                            color="white&#45;&#45;text v-btn theme&#45;&#45;light elevation-2 v-size&#45;&#45;large primary"-->
<!--                                            class="btn-save-popup"-->
<!--                                            text-->
<!--                                            v-if="!this.user.is_email_authentication_enabled"-->
<!--                                            @click="getEmailAuthenticator"-->
<!--                                    >Setup Email Authenticator</v-btn>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </v-col>-->
                        <v-col cols="12" md="6" class="card p-10">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h3>Password</h3>
                                        <p>Setup secure password to access and manage your account.</p>
                                    </div>
                                    <div class="col-md-4 text-right">
                                        <p>
                                            <img
                                                    src="https://img.icons8.com/plasticine/100/000000/password.png"
                                                    style="max-width: 64px"
                                            />
                                        </p>
                                    </div>
                                    <v-btn
                                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                            class="btn btn-primary text-white"
                                            text
                                            @click="showChangePasswordDialog"
                                    >Change password</v-btn>
                                </div>
                            </div>
                        </v-col>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog
                v-model="googleAuthenticatorSetup"
                :loading="isGoogleAuthLoading"
                persistent
                max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Setup MFA</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block">
                            <v-row>
                                <v-col cols="12">
                                    <ul class="mfa-instructions">
                                        <li>Get any Authenticator App (Google or Microsoft) from the Apple AppStore or Google PlayStore</li>
                                        <li>In the app, select account setup</li>
                                        <li>Choose to Scan Barcode, then scan code below</li>
                                    </ul>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-app id="inspire">
                                        <v-row align="center" justify="center">
                                            <v-img
                                                    :src="authenticator.google.image_url"
                                                    :lazy-src="`https://picsum.photos/200/200?random`"
                                                    max-width="200"
                                                    max-height="200"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-row>
                                    </v-app>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <a href="#" @click="googleSecret= !googleSecret">or add your key manually</a>
                                    <v-row class="mfa-secret-key" v-if="googleSecret">
                                        <v-col cols="12">
                                            <p>
                                                <b>Account:</b>
                                                {{authenticator.google.account}}
                                            </p>
                                            <p>
                                                <b>Secret key:</b>
                                                {{authenticator.google.secret_key}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field
                                            label="Enter Verification Code displayed"
                                            v-model="authenticator.google.verification_code"
                                            required
                                            dense
                                            outlied
                                    ></v-text-field>
                                    <p
                                            class="text-danger font-weight-bold"
                                            v-if="$v.authenticator.google.verification_code.$error"
                                    >Please enter a verification code</p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closegoogleAuthenticatorSetup">Close</v-btn>
                    <v-btn
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            @click.prevent="activateGoogleAuthenticator"
                    >Save & activate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="emailAuthenticationSetup" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Setup email authenticator</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block">
                            <v-row>
                                <v-col cols="12">
                                    <ul class="mfa-instructions">
                                        <li>Setup your email and add an extra layer of security to your account.</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            :loading="loading"
                            text
                            @click="closeEmailAuthenticatorDialog"
                    >Close</v-btn>
                    <v-btn
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            :loading="isEmailAuthLoading"
                            @click.once="activateEmailAuthenticator"
                    >Activate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="changePassowrdDialog" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Change password</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            v-model="password.current_password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            label="Old password"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                    <div class="text-danger" v-if="!$v.password.current_password.required">This information is required</div>
                                    <div class="text-danger" v-if="errors && errors.current_password ">{{errors.current_password[0]}}.</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            v-model="password.new_password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            label="New password"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                    <div class="text-danger" v-if="!$v.password.new_password.required">This information is required</div>
                                    <div class="text-danger" v-if="!$v.password.new_password.minLength">Password must have at least 6 letters.</div>

                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            v-model="password.new_confirm_password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            label="Confirm password"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                    <div class="text-danger" v-if="!$v.password.new_password.required">This information is required</div>
                                    <div class="text-danger" v-if="!$v.password.new_confirm_password.sameAsPassword">Passwords must be identical.</div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            :loading="loading"
                            text
                            @click="closeChangePasswordDialog"
                    >Close</v-btn>
                    <v-btn
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            :loading="loading"
                            @click="changePassword"
                    >Request password change</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    </v-app>
</template>
<script>
    import UserService from "@/core/services/user/UserService";
    import {email, minLength, numeric,sameAs, required} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";
    import Apiservice from '@/core/services/api.service';
    const userService = new UserService();

    export default {
        name: "user",
        validations: {
            user: {
                first_name: {required},
                last_name: {required},
                phone: {minLength: minLength(10)},
                email: {email},
                mobile: {numeric, minLength: minLength(10)},
            },
            authenticator: {
                google: {
                    verification_code: { required }
                }
            },
            password:{
                current_password:{required},
                new_password:{required, minLength: minLength(6)},
                new_confirm_password:{required,   sameAsPassword: sameAs('new_password')},
            },
        },
        components: {},
        data() {
            return {
                show1: false,
                show2: true,
                show3: false,
                show4: false,




                file1: null,
                file2: null,
                edit: false,
                isGoogleAuthLoading: false,
                errors: null,
                authenticator: {
                    google: {
                        verification_code: "",
                        image_url: "",
                        account: "",
                        secret_key: ""
                    }
                },
                user: {
                    phone: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    mobile: null,

                },
                password:{
                    current_password:null,
                    new_password:null,
                    new_confirm_password:null,
                },
                showMobileInput: true,
                isEmailAuthLoading: false,
                isChangePasswordLoading: false,
                emailVerificationMailRequested: false,
                isBusy: false,
                mobileRecoverySetup: false,
                mobileNo: null,
                googleAuthenticatorSetup: false,
                emailAuthenticationSetup: false,
                changePassowrdDialog: false,
                googleSecret: false,
                seoDialog: false,
                loading: false,
                page: false
            };
        },
        mounted() {
            this.getUser();
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            getGoogleAuthenticatorCode() {
                this.isGoogleAuthLoading = true;
                userService.requestGoogleAuthenticator().then(res => {
                    this.authenticator.google.account = res.data.account;
                    this.authenticator.google.secret_key = res.data.secret_key;
                    this.authenticator.google.image_url = res.data.image_url;
                    this.googleAuthenticatorSetup = true;
                    this.isGoogleAuthLoading = false;
                });
            },
            closegoogleAuthenticatorSetup() {
                this.googleAuthenticatorSetup = false;
                this.authenticator.google.account = "";
                this.authenticator.google.secret_key = "";
                this.authenticator.google.image_url = "";
            },
            activateGoogleAuthenticator() {
                this.loading = true;
                this.$v.$touch();
                if (this.$v.authenticator.google.$error) {
                } else {
                    userService.activateGoogleAuthenticator(this.authenticator.google).then(
                        res => {
                            this.authenticator.google = {
                                verification_code: "",
                                image_url: "",
                                account: "",
                                secret_key: ""
                            };
                            this.googleAuthenticatorSetup = false;
                            this.isGoogleAuthLoading = false;
                            this.loading = false;
                            if (res.data.status == "OK") {
                                this.user.is_google_two_factor_authentication_enabled = true;
                                this.$snotify.success("MGA activated");
                            } else this.$snotify.error("Unable to activate MFA. Please contact admin");
                        }
                    );
                }
            },

            deActivateGoogleAuthenticator() {
                this.isGoogleAuthLoading = true;
                userService.deActivateGoogleAuthenticator().then(res => {
                    this.isGoogleAuthLoading = false;
                    if (res.data.status == "OK") {
                        this.user.is_google_two_factor_authentication_enabled = false;
                        this.$snotify.success("MFA deactivated");
                    }
                });
            },
            activateEmailAuthenticator() {
                this.isEmailAuthLoading = true;
                this.emailAuthenticationSetup = false;
                this.user.is_email_authentication_enabled = true;
                userService.update(this.currentUser.id, this.user).then(response => {
                    this.$snotify.success("Email authenticator activated");
                    this.isEmailAuthLoading = false;
                    this.emailAuthenticationSetup = false;
                    this.user.is_email_authentication_enabled = true;
                    this.getUser();
                });

            },
            getEmailAuthenticator() {
                this.emailAuthenticationSetup = true;
            },
            sendPasswordResetEmail() {
                Apiservice.post("user/reset/password", {
                    email: this.user.email
                })
                    .then(response => {
                        this.closeChangePasswordDialog();
                        this.emailVerificationMailRequested = true;
                        this.$snotify.success("An email has been sent with password reset link");
                    })
                    .catch(({ response }) => {
                        this.emailVerificationMailRequested = false;
                        this.$snotify.error("Unable to reset your password. Please contact admin.");
                    });

                setTimeout(() => {
                    this.emailVerificationMailRequested = false;
                }, 60000);
            },
            changePassword() {

                    this.$v.password.$touch();
                    if (this.$v.password.$error) {
                        setTimeout(() => {
                            this.$v.password.$reset();
                        }, 3000);
                    } else {
                        this.loading = true;
                        userService.changePassword(this.password).then(response=>{
                            this.changePassowrdDialog = false;
                            this.$snotify.success("Password changed Sucessfully!")
                        }).catch(error=>{
                            this.$snotify.error(error.message)
                            this.errors = error.errors;
                            console.log(error)
                        }).finally(()=>{
                            this.loading = false;

                        })
                    }


            },
            closeEmailAuthenticatorDialog() {
                this.emailAuthenticationSetup = false;
            },
            closeChangePasswordDialog() {
                this.changePassowrdDialog = false;
            },
            showChangePasswordDialog() {
                this.changePassowrdDialog = true;
            },
            getUser() {
              userService.show(this.currentUser.id).then(response => {
                    this.user = response.data.user;
                    if (this.user && this.user.id) {
                        this.edit = true;
                    }
                });
            },
            // saveUser() {
            //     this.$v.user.$touch();
            //     if (this.$v.user.$error) {
            //         setTimeout(() => {
            //             this.$v.user.$reset();
            //         }, 3000);
            //     } else {
            //         this.udpateUser();
            //     }
            // },
            // createSetting() {
            //   userService.store(this.user).then(response => {
            //         this.$snotify.success("Updated successfully");
            //         this.getUser();
            //     });
            // },
            // udpateUser() {
            //   userService.updateUser(this.currentUser.id, this.user).then(response => {
            //         this.$snotify.success("Updated successfully");
            //         this.getUser();
            //     });
            // },

            generateFd() {
                let fd = new FormData();
                for (let key in this.user) {
                    if (
                        key == "logo" &&
                        this.user["logo"] &&
                        this.user["logo"] != null
                    ) {
                        fd.append("logo", this.user[key]);
                    } else if (
                        key == "fav_icon" &&
                        this.user["fav_icon"] &&
                        this.user["fav_icon"] != null
                    ) {
                        fd.append("fav_icon", this.user[key]);
                    }
                    {
                        fd.append(key, this.user[key] ? this.user[key] : "");
                    }
                }
                return fd
            },

            submit() {
                Swal.fire({
                    title: "",
                    text: "Updated",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary"
                });
            }
        }
    };
</script>
